import { render, staticRenderFns } from "./ChangeLog.vue?vue&type=template&id=3a08ed8b&scoped=true&style=height%3A%20100%25&"
import script from "./ChangeLog.vue?vue&type=script&lang=js&"
export * from "./ChangeLog.vue?vue&type=script&lang=js&"
import style0 from "./ChangeLog.vue?vue&type=style&index=0&id=3a08ed8b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a08ed8b",
  null
  
)

export default component.exports